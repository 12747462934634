import {
  Button,
  InputField,
  Select,
  Snackbar,
  Textarea,
} from "@storytel/design-system-admin";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Navigation } from "./Navigation";
import { API } from "../api/sloConfigServer";
import { useFetchSystemOptions } from "../hooks/useFetchSystemOptions";
import { Category } from "../types/category";
import { Option } from "../types/option";
import { SloDefinition } from "../types/sloDefinition";

type Mode = "create" | "edit";

const categoryOptions: Option[] = [
  { name: "latency", value: "latency" },
  { name: "error", value: "error" },
  { name: "misc", value: "misc" },
];

const Wrapper = styled.div`
  width: 600px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  mode: Mode;
};

export const Editor = ({ mode }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [slo, setSlo] = useState<Partial<SloDefinition>>({});
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );
  const [touched, setTouched] = useState(false);
  const systemOptions = useFetchSystemOptions();

  useEffect(() => {
    const fetchSlo = async (id: string) => {
      const data = await API.get(id);
      setSlo(data);
    };
    if (id && mode === "edit") {
      fetchSlo(id);
    }
  }, [id, mode, setSlo]);

  const update = async (id: string, slo: Partial<SloDefinition>) => {
    const response = await API.update(id, slo);

    if (response.status === 204) {
      setSuccessMessage(`SLO saved!`);
      setTouched(false);
    }

    if (response.status === 400) {
      const errors = await response.json();
      setErrorMessage(`Cannot save: ${errors.join(", ")}.`);
    }
  };

  const save = async (slo: Partial<SloDefinition>) => {
    const response = await API.create(slo);

    if (response.status === 200) {
      setSuccessMessage(`SLO created!`);
      const data = (await response.json()) as SloDefinition;
      navigate(`/edit/${data.id}`);
      setTouched(false);
    }

    if (response.status === 400) {
      const errors = await response.json();
      setErrorMessage(`Cannot create: ${errors.join(", ")}.`);
    }
  };

  const handleClick = () => {
    if (mode === "create") {
      return save(slo);
    } else if (mode === "edit" && slo.id) {
      return update(slo.id, slo);
    }
  };

  const SaveButton = () => {
    return (
      <Button
        onClick={handleClick}
        size="small"
        style={{ marginRight: 10 }}
        disabled={!touched}
      >
        {mode === "create" ? "Create" : "Save"}
      </Button>
    );
  };

  const getValue = (field: keyof SloDefinition) =>
    slo[field] === undefined ? "" : String(slo[field]);

  const getHandleChange = (field: keyof SloDefinition) => (value: string) => {
    setTouched(true);
    setSlo({ ...slo, [field]: value });
  };

  return (
    <>
      <Navigation topRightElement={<SaveButton />} />
      <Wrapper>
        <InputField
          key="name"
          disabled={mode === "edit"}
          label="name"
          value={getValue("name")}
          onChange={getHandleChange("name")}
        />
        <Row>
          <InputField
            style={{ width: 280 }}
            key="deployment"
            label="deployment"
            value={getValue("deployment")}
            onChange={getHandleChange("deployment")}
          />
          <Select
            style={{ width: 280 }}
            key="system"
            label="system"
            options={systemOptions}
            value={slo.system}
            onChange={getHandleChange("system")}
          />
        </Row>
        <Row>
          <Select
            style={{ width: 280 }}
            key="category"
            label="category"
            options={categoryOptions}
            value={slo.category}
            onChange={(value) => {
              setTouched(true);
              setSlo({ ...slo, category: value as Category });
            }}
          />
          <InputField
            style={{ width: 280 }}
            key="objective"
            label="objective"
            value={getValue("objective")}
            onChange={getHandleChange("objective")}
          />
        </Row>
        <InputField
          key="successMetric"
          label="success metric"
          value={getValue("successMetric")}
          onChange={getHandleChange("successMetric")}
        />
        <InputField
          key="totalmetric"
          label="total metric"
          value={getValue("totalMetric")}
          onChange={getHandleChange("totalMetric")}
        />
        <Textarea
          key="description"
          label="description"
          value={getValue("description")}
          onChange={getHandleChange("description")}
          rows={3}
        />
        <Snackbar
          message={errorMessage}
          autoHideDuration={10000}
          onClose={() => setErrorMessage(undefined)}
          severity="error"
        />
        <Snackbar
          message={successMessage}
          autoHideDuration={3000}
          onClose={() => setErrorMessage(undefined)}
          severity="success"
        />
      </Wrapper>
    </>
  );
};
