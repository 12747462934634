import "@storytel/storytel-fonts";
import { RootStyle } from "@storytel/design-system-admin";

import { Router } from "./components/Router";

export const App = () => {
  return (
    <RootStyle>
      <Router />
    </RootStyle>
  );
};
