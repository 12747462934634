import {
  AdminTable,
  Button,
  useAdminTable,
} from "@storytel/design-system-admin";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { API } from "../api/sloConfigServer";
import { Search } from "./Search";
import { Navigation } from "./Navigation";
import { SloDefinition } from "../types/sloDefinition";

const TableWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  max-width: 1400px;
`;

const columns = [
  {
    fieldName: "ID",
    renderCell: (slo: SloDefinition) => slo.id,
    percentageWidth: 10,
  },
  {
    fieldName: "Name",
    renderCell: (slo: SloDefinition) => slo.name,
    percentageWidth: 20,
  },
  {
    fieldName: "Deployment",
    renderCell: (slo: SloDefinition) => slo.deployment,
    percentageWidth: 20,
  },
  {
    fieldName: "Category",
    renderCell: (slo: SloDefinition) => slo.category,
    percentageWidth: 15,
  },
  {
    fieldName: "System",
    renderCell: (slo: SloDefinition) => slo.system,
    percentageWidth: 15,
  },
  {
    fieldName: "Objective",
    renderCell: (slo: SloDefinition) => slo.objective,
    percentageWidth: 10,
  },
  {
    fieldName: "Action",
    renderCell: (slo: SloDefinition) => (
      <Link to={`edit/${slo.id}`}>
        <Button size="small" color="secondary">
          edit
        </Button>
      </Link>
    ),
    percentageWidth: 10,
  },
];

export const Table = () => {
  const [fetchedEntries, setFetchedEntries] = useState<SloDefinition[]>([]);
  const [filteredEntries, setFilteredEntries] = useState<SloDefinition[]>([]);

  const createFetchEntries = () => {
    return async () => {
      const data = await API.getAll();

      setFetchedEntries(data.sloDefinitions);
      setFilteredEntries(data.sloDefinitions);

      return {
        nextPageToken: null,
        entries: data.sloDefinitions,
        total: data.sloDefinitions.length,
      };
    };
  };

  const { state, dispatch } = useAdminTable<SloDefinition>(createFetchEntries);

  const handleSearch = (value: string) => {
    const entries = [...fetchedEntries].filter((slo) => {
      return slo.system.includes(value) || slo.deployment.includes(value);
    });
    setFilteredEntries(entries);
    dispatch({ type: "RELOAD" });
    dispatch({
      type: "FETCH_SUCCESS",
      payload: {
        pageToken: null,
        newEntries: entries,
        total: entries.length,
        noTotal: false,
      },
    });
  };

  return (
    <>
      <Navigation />
      <Search onChange={handleSearch} />
      <TableWrapper>
        <AdminTable
          columns={columns}
          createFetchEntries={createFetchEntries}
          renderTopRightComponent={() => (
            <Link to="/create">
              <Button size="small">Create SLO</Button>
            </Link>
          )}
          createTotalLabel={(total: number) => `${total} SLOs`}
          width="1400"
          state={{ ...state, entries: filteredEntries }}
          dispatch={dispatch}
        />
      </TableWrapper>
    </>
  );
};
