import { Card, Typography } from "@storytel/design-system-admin";
import styled from "styled-components";

import { Navigation } from "./Navigation";

const StyledCard = styled(Card)`
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotFound = () => {
  return (
    <>
      <Navigation />
      <StyledCard width="800px" height="200px">
        <Typography variant="semibold" style={{ fontSize: 35 }}>
          Page not found
        </Typography>
      </StyledCard>
    </>
  );
};
