import { Routes, Route } from "react-router-dom";
import { Editor } from "./Editor";
import { NotFound } from "./NotFound";
import { Table } from "./Table";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Table />} />
      <Route path="/create" element={<Editor mode="create" />} />
      <Route path="/edit/:id" element={<Editor mode="edit" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
