import { useEffect, useState } from "react";

import { API } from "../api/sloConfigServer";
import { Option } from "../types/option";

export const useFetchSystemOptions = () => {
  const [systemOptions, setSystemOptions] = useState<Option[]>([]);

  useEffect(() => {
    const fetchSystemOptions = async () => {
      const data = await API.getSystems();
      const options: Option[] = data.systems
        .map((system) => ({
          name: system.label,
          value: system.label,
        }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      setSystemOptions(options);
    };
    fetchSystemOptions();
  }, []);

  return systemOptions;
};
