import { SloDefinition } from "../types/sloDefinition";
import { System } from "../types/system";

const DEV_URL = "http://localhost:8080";
const PROD_URL = "https://sloapi.storytel.dev";

const ROOT_URL = process.env.NODE_ENV === "production" ? PROD_URL : DEV_URL;

const get = async (id: string) => {
  const url = `${ROOT_URL}/slo/${id}`;
  const method = "GET";
  const response = await fetch(url, { method, credentials: "include" });
  const data = await response.json();
  return data;
};

const getAll = async () => {
  const url = `${ROOT_URL}/slo`;
  const method = "GET";
  const response = await fetch(url, { method, credentials: "include" });
  const data: { sloDefinitions: SloDefinition[] } = await response.json();
  return data;
};

const create = async (slo: Partial<SloDefinition>) => {
  const url = `${ROOT_URL}/slo`;
  const method = "POST";
  return await fetch(url, {
    method,
    credentials: "include",
    body: JSON.stringify({ ...slo, objective: Number(slo.objective) }),
    headers: { "Content-Type": "application/json" },
  });
};

const update = async (id: string, slo: Partial<SloDefinition>) => {
  const url = `${ROOT_URL}/slo/${id}`;
  const method = "PUT";
  return await fetch(url, {
    method,
    credentials: "include",
    body: JSON.stringify({ ...slo, objective: Number(slo.objective) }),
    headers: { "Content-Type": "application/json" },
  });
};

const getSystems = async () => {
  const url = `${ROOT_URL}/systems`;
  const method = "GET";
  const response = await fetch(url, { method, credentials: "include" });
  const data: { systems: System[] } = await response.json();
  return data;
};

export const API = {
  get,
  getAll,
  create,
  update,
  getSystems,
};
