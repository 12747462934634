import { Icon, InputField } from "@storytel/design-system-admin";
import styled from "styled-components";

type Props = {
  onChange: (value: string) => void;
};

const Wrapper = styled.div`
  width: 500;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
`;

export const Search = ({ onChange }: Props) => {
  return (
    <Wrapper style={{ width: 500 }}>
      <InputField
        onChange={onChange}
        standalone
        startAdornment={<Icon name="search" />}
      />
    </Wrapper>
  );
};
