import { PrimaryNavigation } from "@storytel/design-system-admin";
import { useNavigate } from "react-router-dom";

type Label = "Home";

const mapLabelToPath: { [key in Label]: string } = {
  Home: "/",
};

type Props = {
  topRightElement?: React.ReactNode;
};

export const Navigation = ({ topRightElement }: Props) => {
  const navigate = useNavigate();

  return (
    <PrimaryNavigation
      items={["Home"] as Label[]}
      onItemSelect={(label: Label) => navigate(mapLabelToPath[label])}
      applicationName="SLOs Admin"
      topRightElement={topRightElement}
    />
  );
};
